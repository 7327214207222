import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store";
import reportWebVitals from "./reportWebVitals";
import { ProSidebarProvider } from "react-pro-sidebar";
import { GoogleOAuthProvider } from "@react-oauth/google";
import HttpsRedirect from "react-https-redirect";
// import * as Sentry from "@sentry/react";

// if (process.env.REACT_APP_ENV === "staging") {
//   Sentry.init({
//     dsn: "https://0b54fc2f6205bd4ea14da12d788aba2f@o4505934449082368.ingest.sentry.io/4506046896144384",
//     integrations: [
//       new Sentry.BrowserTracing({
//         // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//         tracePropagationTargets: [
//           "localhost",
//           /^https:\/\/yourserver\.io\/api/,
//         ],
//       }),
//       new Sentry.Replay(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, // Capture 100% of the transactions
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
// }

// if (process.env.REACT_APP_ENV === "production") {
//   Sentry.init({
//     dsn: "https://dbda4ad7b916916fc4ec758e8ef94e70@o4505934449082368.ingest.sentry.io/4506047061491712",
//     integrations: [
//       new Sentry.BrowserTracing({
//         // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//         tracePropagationTargets: [
//           "localhost",
//           /^https:\/\/yourserver\.io\/api/,
//         ],
//       }),
//       new Sentry.Replay(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, // Capture 100% of the transactions
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
// }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ProSidebarProvider>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}
      >
        <HttpsRedirect>
          <App />
        </HttpsRedirect>
      </GoogleOAuthProvider>
    </ProSidebarProvider>
  </Provider>
);

reportWebVitals();
