/* eslint-disable */

export function API() {
  return "https://api.a2zticketing.com";
}

export default {
  checkUserLogin: "/api/CheckUserLogin",
  jwtToken: "/api/jwt",
  getHostEvent: "/api/getHosterEventImages",
  saveEventDetails: "/api/saveEventsDetails",
  editEventDetails: "/api/editEvents",
  editEventImage: "/api/saveEventsImage",
  editEventSeats: "/api/saveEventsSeats",
  uploadEventImageFile: "/api/uploadEventImageFile",
  getEventsList: "api/getEventsListsAdminImages",
  getEventDetails: "/api/getEvent",
  getActiveEventCategories: "/api/getActiveEventCategories",
  getActiveSeatTypes: "/api/getActiveSeatTypes",
  getEventsPinsList: "/api/getEventsPinsList/EVE-530001",
  saveEventsPins: "/api/saveEventsPins",
  jwtForget: "api/jwtForget",
  SendVerificationCode: "api/SendEmail",
  compareEmailDigit: "api/compareEmailDigit",
  changePassword: "api/changePasswordFromLogin",
  approveEvents: "/api/approveEvents",
  saveNewRegistration: "api/saveNewRegistration",
  saveInvoiceClientsDetails: "/api/saveInvoiceClientsDetails",
  getInvoiceClientsList: "api/getInvoiceClientsList",
  getInvoice: "api/getInvoice",
  saveClientsOrders: "api/saveClientsOrders",
  saveGuestRegistration: "api/saveGuestRegistration",
  savePayment: "api/savePayment",
  saveEventTickets: "api/saveEventTickets",
  sendTicketEmail: "api/sendTicketEmail",
  getSoldTickets: "api/getSoldTickets",
  getRemainingTickets: "api/getRemainingTickets",
  saveMasterEventCategories: "api/saveMasterEventCategories",
  saveMasterSeatTypes: "api/saveMasterSeatTypes",
  getActiveUserType: "/api/getActiveUserType",
  saveMasterUserType: "api/saveMasterUserType",
  getEnquiryAll: "/api/getEnquiryAll",
  getEnquiryDetails: "/api/getEnquiryID",
  saveGeneralEnquiry: "/api/saveGeneralEnquiry",
  getUsersProfile: "/api/getUsersProfile",
  saveBankDetails: "/api/saveBankDetails",
  getBankDetailList: "/api/getBankDetailList",
  getInvoiceClientsPaymentList: "/api/getInvoiceCustomerPaymentList",
  getEventsPinsList: "/api/getEventsPinsList",
  sendEmailOTP: "/api/sendEmailOTP",
  verifyEmailOTP: "/api/verifyEmailOTP",
  getEventTicketsList: "/api/getEventTicketsList",
  getEventRevenueDetails: "/api/getEventRevenueDetails",
  eventModification: "/api/modificationRequest",
  getCountryAll: "/api/getActiveCountry",
  saveMasterCountry: "api/saveMasterCountry",
  apiGetEventTicketsDetails: "/api/getEventTicketsDetails",
  apiResendTicketEmail: "/api/resendTicketEmail",
  apiGetEventTicketsCheckedIn: "api/getEventTicketsCheckedIn",
  apiGetActiveCountry: "/api/getActiveCountry",
  apiGetActiveCurrency: "/api/getActiveCurrency",
  apiSaveMasterCurrency: "/api/saveMasterCurrency",
  apiGetCurrencyAll: "/api/getActiveCurrency",
  apiGetEventTicketsCustomer: "/api/getEventTicketsCustomer",
  apiSaveUserProfileDetails: "/api/saveUserProfileDetails",
  apiUploadProfileImageFile: "/api/uploadProfileImageFile",
  apiEventTicketsPurchaseHistory: "/api/getEventTicketsPurchaseHistory",
  apiPurchaseHistoryForHost: "/api/getEventTicketsUsers",
};
